import { Image, mobile, notDesktop, notMobile, styled, Text } from "@obvio/app";
import { SvgArrow2 } from "@obvio/svg";
import { RichText } from "@obvio/template";
import { Button, CarouselKeen, Stack, Switch } from "@obvio/ui";
import { useAnimation, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

import { useYtContext } from "@/contexts/YTContext";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

type HeroProps = {
  description: string;
  images: ImageAsset[];
  video: string;
};

const Wrap = styled.div`
  height: 65%;
  top: 7rem;
  max-width: calc(100% - 4rem);
  width: 82rem;
  min-height: 50vh;
  position: relative;
  * {
    color: white;
  }
  @media ${notDesktop} {
    height: 55%;
  }
`;

const TextWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 4.5rem;
  width: 35rem;
  @media ${notDesktop} {
    width: 100%;
    top: 7.5rem;
  }
`;

const SelectorWrap = styled.div`
  position: absolute;
  z-index: 1;
  right: ${(theme) => theme.spacing.medium};
  bottom: ${(theme) => theme.spacing.medium};
`;

const AbsoluteCarousel = styled.div`
  position: absolute;
  inset: 0;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`;

export const HeroVideoBlock = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  color: ${(theme) => theme.colors.primary};

  > div:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    scale: 1.25;

    @media (min-aspect-ratio: 16/9) {
      height: 300%;
      top: -100%;
    }

    @media (aspect-ratio: 16/9) {
      height: 100%;
      top: 0;
      width: 300%;
      left: -100%;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 300%;
      left: -100%;
    }
    @media ${mobile} {
      width: 400%;
      height: 400%;
      top: -150%;
      left: -150%;
    }

    > iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
  > div:last-of-type {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    ${(theme) => theme.flexCenter}
  }
`;

type HeroVideoProps = {
  children: ReactElement;
  src: string;
};

function HeroVideo({ children, src }: HeroVideoProps): ReactElement {
  const { Player, PlayerState } = useYtContext();
  const controls = useAnimation();
  const player = useRef();
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const frameRef = useRef<HTMLDivElement | null>(null);

  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (Player && frameRef.current) {
      player.current = new Player(frameRef.current.id, {
        videoId: src,
        playerVars: {
          cc_load_policy: 0, // closed caption
          controls: 0,
          disablekb: 0, // disable keyboard
          iv_load_policy: 3, // annotations
          playsinline: 1, // play inline on iOS
          rel: 0, // related videos
          showinfo: 0, // title
          modestbranding: 3, // youtube logo
        },
        events: {
          onReady: (event) => {
            event.target.playVideo();
            event.target.mute();

            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(() => {
              const currentTime = event.target.getCurrentTime() as number;

              if (currentTime + 11 > event.target.getDuration()) {
                event.target.seekTo(0);
              }
            }, 1000);
          },
          onStateChange: (event) => {
            if (event.data === PlayerState?.PLAYING) {
              void controls.start({
                opacity: 1,
              });
            }
            if (event.data === PlayerState?.ENDED) {
              event.target.playVideo();
            }
          },
        },
      });
    }
  }, [Player, PlayerState?.ENDED, PlayerState?.PLAYING, controls, src]);

  return (
    <HeroVideoBlock>
      <motion.div
        ref={parentRef}
        animate={controls}
        initial={{ opacity: 0 }}
        transition={{ duration: 1.5 }}
      >
        <div id="hero-video-wrap" ref={frameRef} />
      </motion.div>
      <div>{children}</div>
    </HeroVideoBlock>
  );
}

const ImageWrap = styled(Stack)`
  height: 100%;
  width: 100%;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;

  transition: transform 0.3s;
  border-radius: ${(theme) => theme.radius.small};
  overflow: hidden;
`;

type ImageCompProps = {
  image: ImageAsset;
};

export function ImageComp({ image }: ImageCompProps): ReactElement {
  return (
    <ImageWrap>
      <Image img={image} />
    </ImageWrap>
  );
}

const ChildrenWrap = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  ${(theme) => theme.flexCenter}
`;

const Buttons = styled(Stack)`
  position: absolute;
  bottom: 6rem;
  left: 2rem;
  height: unset !important;
  z-index: 1;
  button {
    height: 2.3125rem;
    width: 2.3125rem;
    border-radius: 50%;
    background-color: ${(theme) => theme.colors.primary.dark};
  }
  button:last-of-type {
    > svg {
      transform: rotate(180deg);
    }
  }
`;

type HeroImagesProps = {
  images: ImageAsset[];
  children: ReactElement;
};

function HeroImages({ images, children }: HeroImagesProps): ReactElement {
  return (
    <>
      <AbsoluteCarousel>
        <CarouselKeen
          data={images ?? []}
          render={(v) => <ImageComp image={v} />}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          enhance={({ children, next, previous }) => (
            <>
              {children}
              <Buttons>
                <Button onClick={previous} kind="icon">
                  <SvgArrow2 />
                </Button>

                <Button onClick={next} kind="icon">
                  <SvgArrow2 />
                </Button>
              </Buttons>
            </>
          )}
          options={{
            defaultAnimation: {
              duration: 500,
            },
            autoSwitchTimeout: 2500,
            breakpoints: {
              [notMobile]: {
                loop: true,
                slides: {
                  perView: 1,
                  spacing: 0,
                  origin: "center",
                },
              },
              [mobile]: {
                loop: true,
                slides: {
                  perView: 1,
                  spacing: 0,
                  origin: "center",
                },
              },
            },
          }}
        />
      </AbsoluteCarousel>
      <ChildrenWrap>{children}</ChildrenWrap>
    </>
  );
}

function getVideoUrl(video?: string) {
  if (!video) {
    return { id: "", source: "youtube" };
  }
  let id = "";
  let source: "youtube" | "vimeo" = "youtube";
  try {
    const url = new URL(video);
    switch (url.hostname) {
      case "www.youtube.com": {
        id = url.searchParams.get("v") ?? "";

        break;
      }
      case "youtu.be": {
        id = url.pathname.slice(1);

        break;
      }
      case "vimeo.com": {
        id = url.pathname.slice(1);
        source = "vimeo";

        break;
      }
      // No default
    }
  } catch {
    id = video.slice(Math.max(0, video.lastIndexOf("/") + 1));
    source = /^\d+$/u.test(id) ? "vimeo" : "youtube";
  }

  return { id, source };
}

type View = "image" | "video";

export function Hero({ description, images, video }: HeroProps): ReactElement {
  const [selectedView, setSelectedView] = useState<View>("video");

  const handleSelectChange = (value: boolean) => {
    setSelectedView(value ? "video" : "image");
  };

  const View = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ children }: { children: ReactElement }) =>
      selectedView === "video" ? (
        <HeroVideo src={getVideoUrl(video).id}>{children}</HeroVideo>
      ) : (
        <HeroImages images={images}>{children}</HeroImages>
      ),
    [images, selectedView, video]
  );

  return (
    <View>
      <Wrap>
        <TextWrap>
          <RichText value={description} />
        </TextWrap>
        <SelectorWrap>
          <Stack>
            <Text>Galeria zdjęć</Text>
            <div>
              <Switch
                name="view"
                onChange={handleSelectChange}
                defaultValue={selectedView === "video"}
              />
            </div>
            <Text>Film</Text>
          </Stack>
        </SelectorWrap>
      </Wrap>
    </View>
  );
}
